<template>
  <v-dialog v-model="show" max-width="650" :fullscreen="$vuetify.breakpoint.mobile">
    <v-card v-if="currentLicense">
      <v-row no-gutters>
        <v-card-title>{{ isEditing ? 'Edit License' : 'Add new License' }}</v-card-title>
        <v-btn depressed v-if="$vuetify.breakpoint.mobile" class="ml-auto mr-4 mt-4" icon @click="show = false">
          <v-icon dark>mdi-close-thick</v-icon>
        </v-btn>
      </v-row>
      <v-card-text>
        <v-form v-model="isValid" ref="editLicenseRef">
          <v-row>
            <v-col cols="12">
              <v-text-field :rules="[rules.required]" v-model="currentLicense.name" dense label="Name"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select :items="priceLists" item-text="name" return-object :rules="[rules.required]" v-model="selectedPriceList" dense label="Price list"></v-select>
            </v-col>
            <v-col cols="4">
              <v-select :items="possibleCurrencyCodes" :rules="[rules.required]" item-text="currencyCodeName" item-value="currencyCodeId" v-model="currentLicense.priceListValueCurrencyCodeId" dense label="Currency code"></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field :rules="[rules.required]" :value="priceFactor" dense label="Price factor" disabled ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :rules="[rules.required]"
                :items="companies"
                :item-text="(x) => x.department ? `${x.name} / ${x.department}` : x.name"
                v-model="currentLicense.companies"
                dense
                return-object
                multiple
                item-value="id"
                label="Companies"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="configurations"
                item-text="configurationName"
                v-model="currentLicense.configurations"
                dense
                return-object
                multiple
                item-value="id"
                :rules="[rules.requiredList]"
                label="Configurations"
              ></v-select>
            </v-col>
            <v-col cols="auto">
              <v-switch class="mt-0" v-model="licenseEnabled" label="Enabled" />
            </v-col>
            <v-col cols="auto">
              <v-switch class="mt-0" v-model="currentLicense.isDemonstrationLicense" dense label="Demonstration license" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-block d-md-flex">
        <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="show = false" class="my-4">Cancel</v-btn>
        <v-btn
          depressed
          :block="$vuetify.breakpoint.mobile"
          @click="saveCurrentLicense"
          color="success"
          class="ml-0 ml-md-4 my-4"
          :loading="isSaving"
          :disabled="!isValid"
        >
          <v-icon left>mdi-check</v-icon>
          {{ isEditing ? 'Update License' : 'Add License' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import apiService from '@/services/apiService.js';

export default {
  name: 'CreateEditLicense',
  data: () => ({
    show: false,
    currentLicense: {},
    companies: [],
    priceLists: [],
    isSaving: false,
    isValid: null,
    configurations: [],
    rules: {
      required: (value) => !!value || 'Field cannot be empty.',
      requiredList: (value) => value?.length ? true : 'Field cannot be empty'
    },
  }),
  computed: {
    isEditing() {
      return this.currentLicense?.id ? true : false;
    },
    licenseEnabled: {
      get() {
        return !this.currentLicense.disabled;
      },
      set(val) {
        this.currentLicense.disabled = !val;
      },
    },
    selectedPriceList: {
      get() {
        return this.priceLists.find(x => x.id === this.currentLicense.priceListValuePriceListId)
      },
      set(val) {
        this.currentLicense.priceListValuePriceListId = val?.id;
      }
    },
    possibleCurrencyCodes() {
      return this.selectedPriceList?.values ?? [];
    },
    priceFactor() {
      return this.possibleCurrencyCodes.find(x => x.currencyCodeId === this.currentLicense.priceListValueCurrencyCodeId)?.priceFactor ?? null
    }
  },
  methods: {
    addLicense() {
      this.initialize();
      this.currentLicense = {
        companies: [],
        name: null,
        priceListValueCurrencyCodeId: null,
        priceListValuePriceListId: null,
        isDemonstrationLicense: false,
        disabled: false,
      };
    },
    editLicense(license) {
      this.initialize();
      this.currentLicense = _.cloneDeep(license);
    },
    async saveCurrentLicense() {
      this.isSaving = true;
      await apiService
        .createOrEditLicense(this.currentLicense)
        .then(() => {
          this.show = false;
          this.$emit('license-updated');
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    async initialize() {
      this.companies = _.orderBy(await apiService.getCompanies(), x => x.name);
      this.configurations = _.orderBy(await apiService.getConfigurations(), x => x.configurationName);
      this.priceLists = _.orderBy(await apiService.getPriceLists(), x => x.name);
      this.show = true;
    },
  },
  created() {},
};
</script>

<style>
</style>